import React from 'react'
import ReactDOM from "react-dom"; 
 
class Paginate extends React.Component {
  constructor(props) {
    super(props); 
    let { currentPage } = this.props;

    this.state = { 
      inputPage: currentPage
    }
    this.changeState = this.changeState.bind(this);   
    this.goToPage = this.goToPage.bind(this);   
    this.keyDownEvent = this.keyDownEvent.bind(this);    
    this.thisComponentStyle = this.thisComponentStyle.bind(this);
  } 

  thisComponentStyle(){
    const id = "PaginateStyle";
    if( document.getElementById(id) == null ){
      const $style = document.createElement("style");
      $style.setAttribute("id", id);
      document.head.appendChild($style); 
      $style.innerHTML = `  
        .pagination {
          display: inline-block;
          padding-left: 0;
          margin: 20px 0;
          border-radius: 0;
        }
        .pagination > li {
          display: inline;
        }
        .pagination > li > a,
        .pagination > li > span {
          position: relative;
          float: left;
          padding: 6px 12px;
          line-height: 1.538462;
          text-decoration: none;
          color: #2c3e50;
          background-color: #fff;
          border: 1px solid #cad6e2;
          margin-left: -1px;
        }
        .pagination > li:first-child > a,
        .pagination > li:first-child > span {
          margin-left: 0;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
        .pagination > li:last-child > a,
        .pagination > li:last-child > span {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
        .pagination > li > a:hover, .pagination > li > a:focus,
        .pagination > li > span:hover,
        .pagination > li > span:focus {
          z-index: 2;
          color: #fff;
          background-color: #2c3e50;
          border-color: #2c3e50;
        }
        .pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
        .pagination > .active > span,
        .pagination > .active > span:hover,
        .pagination > .active > span:focus {
          z-index: 3;
          color: #fff;
          background-color: #2c3e50;
          border-color: #2c3e50;
          cursor: default;
        }
        .pagination > .disabled > span,
        .pagination > .disabled > span:hover,
        .pagination > .disabled > span:focus,
        .pagination > .disabled > a,
        .pagination > .disabled > a:hover,
        .pagination > .disabled > a:focus {
          color: #cad6e2;
          background-color: #fff;
          border-color: #cad6e2;
          cursor: not-allowed;
        }
      `; 
    } 
  }

  changeState(e, key){ 
    let setState = {};
    setState[key] = $(e.target).val();
    this.setState(setState); 
  }

  keyDownEvent(e,params){ 
    let that = this;
    if(e.type && e.type === "keydown") {
      if(e.keyCode == 13 || e.keyCode == 9){
        if( that.props.isAjax ){ 
          that.props.onChange.apply(this, [e.target.value]);  
        } else {
          this.goToPage(e); 
        } 
      }  
    } 
  }  
     
  goToPage(e){  
    let { currentPage } = this.props; 
    let { inputPage } = this.state; 
    if (inputPage != currentPage) {
      location.href = location.href.removeUrlHash().updateQueryStringParameter("page",inputPage);
    } 
    e.preventDefault();
    e.stopPropagation();
  }     
    
  componentDidMount() {
    let that = this;  
    that.thisComponentStyle();
    $(document).ready(function(){
      $(document).on("click","ul.pagination li.disabled",function(){
        return false;
      });
    });
  }

  render () {
    let that = this; 
    let { pageSize, isLastPage, currentPage } = this.props;
    let { inputPage } = this.state;
    let thisLocation = location.href.removeUrlHash(); 

    return <div className="col-md-12 text-center no-print"><ul className="pagination"> 
      <li className={ (currentPage != 1) ? "" : "disabled" }>
        <a onClick={(e) => { 
          if( that.props.isAjax ){ 
            if (currentPage != 1) {
              that.props.onChange.apply(that, [1]);  
            } 
            e.preventDefault();
            e.stopPropagation(); 
          } 
        }} href={ (currentPage - 1) > 0 ? thisLocation.updateQueryStringParameter("page", 1 ) : "" }>«</a>
      </li> 
      <li className={ (currentPage - 1) > 0 ? "" : "disabled" }>
        <a onClick={(e) => { 
          if( that.props.isAjax ){ 
            if ((currentPage - 1) > 0) {
              that.props.onChange.apply(that, [currentPage - 1]);  
            } 
            e.preventDefault();
            e.stopPropagation(); 
          } 
        }} href={ (currentPage - 1) > 0 ? thisLocation.updateQueryStringParameter("page", currentPage - 1 ) : "" }>上一頁</a>
      </li> 
      <li className="active">
        <input onKeyDown={(e) => this.keyDownEvent(e)} onChange={(e) => this.changeState(e, "inputPage")} style={{ border: "1px solid #686262", marginLeft: "1px", marginRight: "2px", width: "45px", height: "38px", float: "left", textAlign: "center" }} type="text" defaultValue={ inputPage } /> 
        { inputPage != currentPage && 
          <a style={{ cursor: "pointer" }} href="#" onClick={(e) => { 
            if( that.props.isAjax ){ 
              that.props.onChange.apply(that, [inputPage]); 
              e.preventDefault();
              e.stopPropagation(); 
            } else {
              that.goToPage(e) 
            }
          }}>前往指定頁面</a>
        } 
      </li>
      <li className={ isLastPage ? "disabled" : "" }>
        <a onClick={(e) => { 
          if( that.props.isAjax ){ 
            if (!isLastPage) {
              that.props.onChange.apply(that, [currentPage + 1]);  
            } 
            e.preventDefault();
            e.stopPropagation(); 
          }
        }} href={ isLastPage ? "" : thisLocation.updateQueryStringParameter("page", currentPage + 1 ) }>下一頁</a>
      </li> 
      <li className={ isLastPage ? "disabled" : "" }>
        <a onClick={(e) => { 
          if( that.props.isAjax ){ 
            if (!isLastPage) {
              that.props.onChange.apply(that, ["last"]);  
            } 
            e.preventDefault();
            e.stopPropagation(); 
          }
        }} href={ isLastPage ? "" : thisLocation.updateQueryStringParameter("page", "last" ) }>»</a>
      </li>
    </ul></div>;
  }
}

Paginate.defaultProps = {
  currentPage: 1, 
  pageSize: 20, 
  isLastPage: true, 
  isAjax: false,
  onChange: function(){}
};
 
export { Paginate }  
