import React from "react";
import ReactDOM from "react-dom/client";    
import { CONSTANTS } from "../bundles/constants.es6.jsx"; 
import { SearchMenu } from "../components/search_menu.es6.jsx"; 
import { Paginate } from "../components/paginate.es6.jsx"; 
import { OrderStatus } from "../components/order_status.es6.jsx"; 
import Swal from "sweetalert2"; 
import * as Models from '../bundles/model.js'; 
import plug1 from '../bundles/welcome-page/jquery-migrate-3.0.0.min.js';
import plug2 from '../bundles/welcome-page/bootstrap.bundle.min.js';
import plug3 from '../bundles/welcome-page/wow.min.js';
import plug4 from '../bundles/welcome-page/jquery.fancybox.js';
import plug5 from '../bundles/welcome-page/lity.js';
import Swiper from '../bundles/welcome-page/swiper.min.js';
import plug7 from '../bundles/welcome-page/jquery.waypoints.min.js';
import plug8 from '../bundles/welcome-page/jquery.counterup.js';
import plug9 from '../bundles/welcome-page/scrollIt.min.js';
  
// import wowMin from '../bundles/welcome-page/wow.min.js';
// import jqueryFancyBox from '../bundles/welcome-page/jquery.fancybox.js';
// import lity from '../bundles/welcome-page/lity.js';

// import welcomePageMain from '../bundles/welcome-page/main.js';

// class WelcomeIndex extends React.Component {
//   constructor(props) {
//     super(props);
 
//     this.state = { 
//       // "orderModel": (new Models.OrderModel()),
//       // "pageSize" : 20,
//       // "currentPage": this.props.reactProps.current_page, 
//       // "isLastPage": this.props.reactProps.is_last_page, 
//       // "showDatas" : true,
//       // "currentSite": this.props.reactProps.current_site,
//       // "datas": datas
//     }
 
//     // this.setCurrentPage = this.setCurrentPage.bind(this);
//     this.thisComponentStyle = this.thisComponentStyle.bind(this);    
//   }
  
//   thisComponentStyle(){
//     const id = "WelcomeStyle";
//     if( document.getElementById(id) == null ){
//       const $style = document.createElement("style");
//       $style.setAttribute("id", id);
//       $style.setAttribute("class", "react-component-style");
//       document.head.appendChild($style); 
//       $style.innerHTML = `  
 

//       `; 
//     } 
//   }
 
//   componentDidMount() { 
//     const that = this;  
//     that.thisComponentStyle();  
//     if( window.opener && location.search.getQueryString("uuid") ){
//       let thisWindow = new Models.WindowModel({uuid: location.search.getQueryString("uuid")}); 
//       thisWindow.post_message_to_window(
//         window.opener,
//         "重新執行", 
//         { 
//           uuid: location.search.getQueryString("uuid")
//         }
//       ); 
//     }
//   }
    
//   render() {  
//     const that = this;   
//     return <React.Fragment>  
//       <div className="navbar-container" style={{ backgroundColor: "#f07345" }}>
//         <nav className="navbar navbar-expand-lg navbar-dark" data-overlay data-sticky="top">
//           <div className="container">
//             <a className="navbar-brand" href="#">
//               <img src="/logo.png" style={{ height: "60px" }} />
//             </a>
//             <div className="collapse navbar-collapse order-3 order-lg-2 justify-content-lg-end" id="navigation-menu">
//               <ul style={{ fontSize: "18px", fontWeight: "bold" }} className="navbar-nav my-3 my-lg-0">
//                 <li className="nav-item">
//                   <div className="dropdown">
//                     <a style={{ color: "white" }} className="nav-link nav-item aboutUs" role="button" href="#aboutUs">關於我們</a>
//                   </div>
//                 </li>
//                 <li className="nav-item">
//                   <div className="dropdown">
//                     <a data-scroll-nav="2" style={{ color: "white" }} className="nav-link nav-item serviceIn" role="button" href="#aboutUs">服務內容</a>
//                   </div>
//                 </li>
//                 <li className="nav-item">
//                   <div className="dropdown">
//                     <a style={{ color: "white" }} className="nav-link nav-item systemSpec" role="button" href="#systemSpec">系統特色</a>
//                   </div>
//                 </li>
//                 <li className="nav-item">
//                   <div className="dropdown">
//                     <a style={{ color: "white" }} className="nav-link nav-item priceItem" role="button" href="#priceItem">收費標準</a>
//                   </div>
//                 </li>  
//                 { !that.props.reactProps.current_user &&
//                   <li className="nav-item">
//                     <div className="dropdown">
//                       <a style={{ border: "1px solid white", borderRadius: "5px", color: "white" }} className="nav-link nav-item" role="button" href="/users/sign_in">登入</a>
//                     </div>
//                   </li> 
//                 }
//                 { !that.props.reactProps.current_user &&
//                   <li className="nav-item">
//                     <div className="dropdown">
//                       <a style={{ border: "1px solid white", borderRadius: "5px", color: "white" }} className="nav-link nav-item ml-2" role="button" href="/users/sign_up">註冊</a>
//                     </div>
//                   </li> 
//                 }                
//                 { that.props.reactProps.current_user &&
//                   <li className="nav-item">
//                     <ul className="navbar-nav ml-md-auto">
//                       <li className="nav-item dropdown">
//                         <a style={{ color: "white" }} className="nav-link dropdown-toggle mr-md-2" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                           { that.props.reactProps?.current_user?.name }
//                         </a>
//                         <div style={{ backgroundColor: "#f07345" }} className="dropdown-menu dropdown-menu-right" aria-labelledby="bd-user">
//                           <a style={{ color: "white" }} className="dropdown-item" href="/sites/new" target="_blank">申請新網站</a> 
//                           <div className="dropdown-divider"></div>                          
//                           <a style={{ color: "white" }} className="dropdown-item" rel="nofollow" data-method="delete" href="/users/sign_out" onClick={e => {
//                             let $form = $("<form action='/users/sign_out' method='post'></form>");
//                             $form.append('<input type="hidden" name="_method" value="delete">');
//                             $form.append(`<input type="hidden" name="authenticity_token" value="${ $('meta[name="csrf-token"]').attr('content') }">`);
//                             $form.appendTo("body");
//                             $form.submit();
//                             e.preventDefault(); 
//                             e.stopPropagation(); 
//                           }}>登出</a>
//                         </div>
//                       </li>
//                     </ul>
//                   </li> 
//                 }  
//               </ul>
//             </div>
//           </div>
//         </nav>
//       </div>

//       <div className="bg-gradient position-relative" data-overlay>
//         <section className="pb-0 pt-5">
//           <div className="container">
//             <div className="row justify-content-around align-items-center">
//               <div className="col-lg-6 col-xl-5 mb-4 mb-sm-5 mb-lg-0">
//                 <div className="text-white text-center text-lg-left mb-4 mb-xl-5" data-aos="fade-up">
//                   <h3>一次貼文 , 多元上架 , 輕鬆方便。<br/><br/>
//                     一次付款 , 多元功能 , 清楚明瞭。</h3>
//                   <p className="lead">
//                   </p>
//                 </div>
//               </div>
//               <div className="col-lg-6 col-xl-7 col-md-9 slider_ad  mt-5" data-aos="fade-left" data-aos-delay="250">
//                 <img src="/assets/j/img/slider-46b521e0b012b49e982ad57df29fe6d1.png" alt="" className="img-fluid" />
//               </div>
//             </div>
//           </div> 
//           <a className="button-rect btn_down m-auto" href="#btn-rect">
//             <img src="/assets/j/img/icon_scrolldown-6ed317cfe778b8ea6dd84491f2e89cac.svg" alt=""/></a>
//           <div className="divider divider-bottom bg-white"></div>
//         </section>

//       </div>
//       <section className="pb-0 pt-5 mt-5" id="aboutUs">
//         <div className="container" id="btn-rect">
//           <div className="row justify-content-center py-md-4">
//             <div className="row section-title justify-content-center mt-5">
//               <div className="col-md-6 col-lg-6 col-xl-5">
//                 <h3 className="display-5 text-center">學習！讓生意更升級！</h3>
//                 <h3 className="display-5 text-center">團媽UP 工作生活都UP</h3>
//                 <div className="text-left mt-5 title_contant m-4">『團媽』一詞，是社群網路中創業者的代表。
//                   創造生活樂趣給消費者，也創造工作機會！
//                   解決面對家庭與工作蠟燭兩頭燒的團媽壓力，
//                   因而追求【提高團媽工作效能】的系統，是我們一路
//                   以來的願景，也是系統開發秉持的態度！
//                   因而我們期許透過系統的運用，讓創業者朝著更優質的數位系統前進！<br /><br />
//                   <h5 className="mt-3">生意要做大，好的管理系統是您最佳的選擇！</h5>
//                 </div>
//               </div>

//             </div>
//             <div className="hand_01 d-lg-block d-none"><img src="/assets/j/img/about_hand01-227bcd97869ff7c81b0d664cd2680bde.png" alt="" /></div>
//             <div className="hand_02 d-lg-block d-none"><img src="/assets/j/img/about_hand02-6440f382ff49693cf92abf23694bfd64.png" alt="" />
//             </div>
//           </div>
//           <div className="row">
//             <div className="col">
//               <hr className="mt-5 mb-0"/>
//             </div>
//           </div>
//         </div>
//       </section>
   







//       <footer className="bg-primary-3 text-white links-white pb-4 footer-1" id="call">
//         <div className="container">
//           <div className="row">
//             <div className="col-xl-3 mr-xl-5 col-md-6">
//               <div className="footer_logo mb-3"><img src="/assets/j/img/logo-bed7482a27abcf07f4b2ef906c20443a.png" alt=""/></div>
//               <p>
//                 系統是解決問題！ 不是有租就好！<br />
//                 系統是遇到困難！ 有人正面處理！<br />
//                 系統是營運最大利器！ <br />
//                 不要省錢丟了市場！<br />
//                 <div className="mt-3">歡迎有任何問題都可以詢問喔！</div>
//               </p>
//             </div>
//             <div className="col-xl-auto mr-xl-5 col-md-6">
//               <h5>團媽UP</h5>
//               <ul className="nav flex-row flex-md-column">
//                 <li className="nav-item mr-3 mr-md-0">
//                   <a href="#aboutUs" className="aboutUs nav-link px-0 py-2">關於我們</a>
//                 </li>
//                 <li className="nav-item mr-3 mr-md-0">
//                   <a href="#serviceIn" className="serviceIn nav-link px-0 py-2">服務內容</a>
//                 </li>
//                 <li className="nav-item mr-3 mr-md-0">
//                   <a href="#systemSpec" className="systemSpec nav-link px-0 py-2">系統特色</a>
//                 </li>
//                 <li className="nav-item mr-3 mr-md-0">
//                   <a href="#priceItem" className="priceItem nav-link px-0 py-2">收費標準</a>
//                 </li>
//                 <li className="nav-item mr-3 mr-md-0">
//                   <a href="/privacy_and_terms" className="nav-link px-0 py-2">隱私權政策與服務條款</a>
//                 </li>
//               </ul>
//             </div>
//             <div className="col-lg mt-2 mt-md-5 mt-lg-0 order-lg-3 order-xl-4">
//               <h5>聯絡我們</h5>
//               <div className="col-12 mt-3 pl-0">
//                 <a href="https://www.facebook.com/MammyUp.tw" className="hover-fade-out d-flex align-items-center"
//                   target="_blank">
//                   <div className="facebook_icon d-flex justify-content-center align-items-center">
//                     <img src="/assets/j/img/facebook-2e0a7b97dd863eff96b081c647b86fab.svg" alt="Facebook" className="icon icon-xs"/>
//                   </div>
//                   <div className="ml-2">粉絲專頁</div>
//                 </a>
//               </div>
//               <div className="col-12 mt-3 pl-0 mb-5">
//                 <a href="https://line.me/R/ti/p/fS6adWbRTW" className="hover-fade-out d-flex align-items-center"
//                   target="_blank">
//                   <div className="line_icon d-flex justify-content-center align-items-center">
//                     <img src="/assets/j/img/line-217f3a7f063ff6202dd888075a750e4e.png" alt="LINE" className=""/>
//                   </div>
//                   <div className="ml-2">專屬ID @mammyup</div>
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col">
//               <hr/>
//             </div>
//           </div>
//           <div className="row flex-column flex-lg-row align-items-center justify-content-center  text-center">
//             <div className="col-auto">
//               <div className="d-flex flex-column flex-sm-row align-items-center text-small">
//                 <div className="text-muted"> 本站最佳瀏覽環境請使用Google Chrome、Firefox或IE10以上版本 Powered by &copy; 團媽UP
//                 </div>
//               </div>
//             </div>

//           </div>
//         </div>
//       </footer> 
//     </React.Fragment>;
//   }  
// } 
  
// window.reactComponents = window.reactComponents ? window.reactComponents : {};
// window.reactComponents.WelcomeIndex = WelcomeIndex;


 
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================
//========================================================================




class WelcomeIndex extends React.Component {
  constructor(props) {
    super(props);

    //除去錨點
    // history.replaceState(null, document.title, window.location.pathname);
 
    this.state = { 
      // "orderModel": (new Models.OrderModel()),
      // "pageSize" : 20,
      // "currentPage": this.props.reactProps.current_page, 
      // "isLastPage": this.props.reactProps.is_last_page, 
      // "showDatas" : true,
      // "currentSite": this.props.reactProps.current_site,
      // "datas": datas
    }
 
    this.thisComponentStyle = this.thisComponentStyle.bind(this);   
    this.thirdPartyPlugIn = this.thirdPartyPlugIn.bind(this);  

    this.propsToInputData = this.propsToInputData.bind(this);   
    this.inputDataToFormData = this.inputDataToFormData.bind(this);   
  }
 
  propsToInputData(){
    let that = this;
    let { reactProps } = this.props;   
 
    let sites = reactProps.sites ?? [];

    for(let site of sites){
      site.isActive = site.latest_system_confirmed_charged_record && 
                      ( new Date(site.latest_system_confirmed_charged_record.valid_end_date).getTime() >= new Date().getTime() ) 
    }

    that.state.inputData = {
      ...that.state.inputData,
      sites: sites ?? []
    }; 
  } 
 
  inputDataToFormData(){
    let that = this;
    let {   
    } = this.state.inputData;

    that.setState({
      formData: {
        ...that.state.formData 
      } 
    }); 
  }   
 
  thirdPartyPlugIn(){
    $( function() { 
      var wind = $(window);

      let wow = new plug3.WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 200,
        mobile: false,
        live: false
      });
      wow.init();

      // scrollIt
      $.scrollIt({
        upKey: 38,                // key code to navigate to the next section
        downKey: 40,              // key code to navigate to the previous section
        easing: 'linear',         // the easing function for animation
        scrollTime: 700,          // how long (in ms) the animation takes
        activeClass: 'active',    // class given to the active nav element
        onPageChange: null,       // function(pageIndex) that is called when page is changed
        topOffset: -100            // offste (in px) for fixed top navigation
      });
 
      // ---------- nav scroll -----------
      wind.on("scroll", function() { 
        var bodyScroll = wind.scrollTop(),
            navbar = $(".navbar") 
        if (bodyScroll > 200) { 
          navbar.addClass("nav-scroll"); 
        } else { 
          navbar.removeClass("nav-scroll");
        }
      });
         
      // ---------- to top -----------
      wind.on("scroll", function() { 
        var bodyScroll = wind.scrollTop(),
            toTop = $(".to_top")

        if (bodyScroll > 700) { 
          toTop.addClass("show"); 
        } else { 
          toTop.removeClass("show");
        }
      });
        
      // ------- to top -------
      $('.to_top').click(function() {
        $('html, body').animate({
          scrollTop: 0
        }, 200);
        return false;
      });

      // ------ sidemenu 7 ------
      $(".side_menu_btn").on("click", function() {
        $(this).toggleClass("active");
        $("#side_menu").toggleClass("show");
        $(".side_overlay").toggleClass("show");
      })

      $(".side_menu_cls").on("click", function() {
        $("#side_menu").removeClass("show");
        $(".side_overlay").removeClass("show");
      })

      $(".side_overlay").on("click", function() {
        $("#side_menu").removeClass("show");
        $(this).removeClass("show");
      })

      // --------- navbar active ---------
      $(".faq-category li a").on("click", function() {
        $(this).addClass("active");
        $(this).parent().siblings().children("a").removeClass("active");
      })
 
      /* ==============  priceing_s2  ============== */
      $('#monthly-input2').on('change', function() {
        $(".yearly_price , .monthly_price").toggleClass("show");
      });
      /* ==============  priceing_s5  ============== */
      $('#monthly-input').on('change', function() {
        $(".monthly_price").show();
        $(".monthly_price").siblings(".yearly_price").hide();
      });
      $('#yearly-input').on('change', function() {
        $(".yearly_price").show();
        $(".yearly_price").siblings(".monthly_price").hide();
      });
 
      /* ===============================  projects style-12  =============================== */
      var containerEl = document.querySelector('.projects.style-12 .items');
      // var mixer = mixitup(containerEl);
      // ----- active & unactive -----
      $(".mix_tabs").on("click" , ".tab-link" , function(){
        $(this).addClass("active").siblings().removeClass("active");
      })

      // -------- counter --------
      $('.counter').countUp({
        delay: 10,
        time: 2000
      });

      // -------- blog effect --------
      $(".blog .blog_box").mouseenter(function(){
        $(this).find(".text").slideDown();
      })
      $(".blog .blog_box").mouseleave(function(){
        $(this).find(".text").slideUp();
      })

        // --------- fav btn ---------
      $(".fav-btn").on("click" , function(){
        $(this).toggleClass("active");
      })

      // --------- grid list view ---------
      $(".grid-list-btns").on( "click", ".bttn" , function(){
        $(this).addClass("active").siblings().removeClass("active");
      })

      $(".grid-list-btns").on( "click", ".list-btn" , function(){
        $(".products-content .products").addClass("list-view");
      })

      $(".grid-list-btns").on( "click", ".grid-btn" , function(){
        $(".products-content .products").removeClass("list-view");
      })


      // ------------ increase products -----------
      $(".qt-plus").click(function() {
        $(this).parent().children(".qt").html(parseInt($(this).parent().children(".qt").html()) + 1);
      });

      $(".qt-minus").click(function() { 
        child = $(this).parent().children(".qt"); 
        if (parseInt(child.html()) > 1) {
          child.html(parseInt(child.html()) - 1);
        } 
        $(this).parent().children(".full-price").addClass("minused"); 
      }); 
    });



     // ---------- hover dropdown -----------
     const $dropdown = $(".dropdown");
     const $dropdownToggle = $(".dropdown-toggle");
     const $dropdownMenu = $(".dropdown-menu");
     const showClass = "show";

    $(window).on("load resize", function() {
      if (this.matchMedia("(min-width: 768px)").matches) {
        $dropdown.hover(
          function() {
            const $this = $(this);
            $this.addClass(showClass);
            $this.find($dropdownToggle).attr("aria-expanded", "true");
            $this.find($dropdownMenu).addClass(showClass);
          },
          function() {
            const $this = $(this);
            $this.removeClass(showClass);
            $this.find($dropdownToggle).attr("aria-expanded", "false");
            $this.find($dropdownMenu).removeClass(showClass);
          }
        );
      } else {
        $dropdown.off("mouseenter mouseleave");
      }
    });

    //=================================================
    // ------------ swiper sliders -----------
    $(document).ready(function() {
      var swiper = new Swiper('.portfolio-slider .swiper-container', {
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 1000,
        pagination: {
          el: ".portfolio-slider .swiper-pagination"
        },
        navigation: {
          nextEl: '.portfolio-slider .swiper-button-next',
          prevEl: '.portfolio-slider .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 2,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });

      // ------------ blog sliders -----------
      var swiper = new Swiper('.blog_slider .swiper-container', {
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 1000,
        pagination: {
          el: ".blog_slider .swiper-pagination"
        },
        navigation: {
          nextEl: '.blog_slider .swiper-button-next',
          prevEl: '.blog_slider .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        // loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 2,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          }
        }
      });

      // ------------ services sliders -----------
      var swiper = new Swiper('.services_slider .swiper-container', {
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 1000,
        pagination: {
          el: ".services_slider .swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: '.services_slider .swiper-button-next',
          prevEl: '.services_slider .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 2,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });

      // ------------ works sliders -----------
      var swiper = new Swiper('.works_slider .swiper-container', {
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 1000,
        pagination: {
          el: ".works_slider .swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: '.works_slider .swiper-button-next',
          prevEl: '.works_slider .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 2,
          }
        }
      });

      // ------------ works sliders -----------
      var swiper = new Swiper('.reviews_slider .swiper-container', {
        effect: "fade",
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1000,
        pagination: {
          el: ".reviews_slider .swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: '.reviews_slider .swiper-button-next',
          prevEl: '.reviews_slider .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
      });

      // ------------ works sliders -----------
      var swiper = new Swiper('.testimonial-slider.style-3 .swiper-container', {
        slidesPerView: 3,
        spaceBetween: 100,
        speed: 1000,
        pagination: {
          el: ".testimonial-slider.style-3 .swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: '.testimonial-slider.style-3 .swiper-button-next',
          prevEl: '.testimonial-slider.style-3 .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });

      // ------------ works sliders -----------
      var swiper = new Swiper('.screenshots-slider.style-4 .swiper-container', {
        slidesPerView: 5,
        spaceBetween: 0,
        centeredSlides: true,
        speed: 1000,
        pagination: false,
        navigation: false,
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 2,
          },
          787: {
            slidesPerView: 3,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 5,
          }
        }
      });
        
      // ------------ clients sliders -----------
      let SwiperBottom = new Swiper('.clients-slider5 .swiper-container', {
        spaceBetween: 0,
        centeredSlides: true,
        slidesPerView: 6,
        speed: 6000,
        autoplay: {
          delay: 1,
        },
        loop: true,
        allowTouchMove: false,
        disableOnInteraction: true,
        breakpoints: {
          0: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 2,
          },
          787: {
            slidesPerView: 3,
          },
          991: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 6,
          }
        }
      });
      
      // ------------ testimonial sliders -----------
      var swiper = new Swiper('.testimonial-slider.style-5 .swiper-container', {
        slidesPerView: 4,
        spaceBetween: 0,
        speed: 1000,
        pagination: {
          el: ".testimonial-slider.style-5 .swiper-pagination",
          clickable: true,
        },
        navigation: false,
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          }
        }
      });

      // ------------ services sliders -----------
      var swiper = new Swiper('.services-slider.style-6 .swiper-container', {
        slidesPerView: 6,
        centeredSlides: true,
        spaceBetween: 0,
        speed: 1000,
        pagination: false,
        navigation: false,
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 6,
          }
        },
        on: {
          slideChange: function () {
            var activeIndex = this.activeIndex;
            var realIndex = this.slides.eq(activeIndex).attr('data-swiper-slide-index');
            $('.swiper-slide').removeClass('swiper-slide-nth-prev-2 swiper-slide-nth-next-2');
            $('.swiper-slide[data-swiper-slide-index="'+realIndex+'"]').prev().prev().addClass('swiper-slide-nth-prev-2');
            $('.swiper-slide[data-swiper-slide-index="'+realIndex+'"]').next().next().addClass('swiper-slide-nth-next-2');
          },
        }
      });

      // ------------ works sliders -----------
      var swiper = new Swiper('.slider-3items .swiper-container', {
        slidesPerView: 3,
        spaceBetween: 0,
        speed: 1000,
        pagination: {
          el: ".slider-3items .swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: '.slider-3items .swiper-button-next',
          prevEl: '.slider-3items .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });

      // ------------ works sliders -----------
      var swiper = new Swiper('.testi-slider.style-6 .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1000,
        pagination: false,
        navigation: {
          nextEl: '.testi-slider.style-6 .swiper-button-next',
          prevEl: '.testi-slider.style-6 .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true
      });

      // ------------ works sliders -----------
      var swiper = new Swiper('.blog-details-slider .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        effect: "fade",
        speed: 1000,
        pagination: {
          el: ".blog-details-slider .swiper-pagination",
          clickable: "true",
        },
        navigation: {
          nextEl: '.blog-details-slider .swiper-button-next',
          prevEl: '.blog-details-slider .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true
      });

      // ------------ related-postes-slider -----------
      var swiper = new Swiper('.related-postes-slider .swiper-container', {
        slidesPerView: 3,
        spaceBetween: 80,
        centeredSlides: true,
        speed: 1000,
        pagination: false,
        navigation: {
          nextEl: '.related-postes-slider .swiper-button-next',
          prevEl: '.related-postes-slider .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });

      // ------------ related-postes-slider -----------
      var swiper = new Swiper('.culture-slider .swiper-container', {
        slidesPerView: 4,
        spaceBetween: 30,
        centeredSlides: true,
        speed: 1000,
        pagination: {
          el: ".swiper-pagination",
          clickable: "true",
        },
        navigation: false,
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          }
        }
      });

      // ------------ gallery-thumbs-slider -----------
      var galleryThumbs = new Swiper('.product .gallery-thumbs', {
        spaceBetween: 20,
        slidesPerView: 6,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        direction: 'vertical',
      });
      var galleryTop = new Swiper('.product .gallery-top', {
        spaceBetween: 10,
        navigation: false,
        thumbs: {
          swiper: galleryThumbs
        }
      });

      // ------------ related-postes-slider -----------
      var swiper = new Swiper('.related-products-slider .swiper-container', {
        slidesPerView: 5,
        spaceBetween: 24,
        // centeredSlides: true,
        speed: 1000,
        pagination: false,
        navigation: {
          nextEl: '.related-products-slider .swiper-button-next',
          prevEl: '.related-products-slider .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 5,
          }
        }
      });

      // ------------ features-slider7 -----------
      var swiper = new Swiper('.features-slider7 .swiper-container', {
        spaceBetween: 50,
        centeredSlides: true,
        // slidesPerView: 6,
        speed: 10000,
        autoplay: {
          delay: 1,
        },
        loop: true,
    //   allowTouchMove: false,
        disableOnInteraction: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });

      // ------------ projects-slider7 -----------
      var swiper = new Swiper('.projects-slider7 .swiper-container', {
        // slidesPerView: 5,
        spaceBetween: 40,
        // centeredSlides: true,
        speed: 1000,
        pagination: false,
        navigation: {
          nextEl: '.projects.style-7 .swiper-button-next',
          prevEl: '.projects.style-7 .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 5000,
        },
        observer: true,
        observeParents: true,
        loop: false,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 2,
          }
        }
      });

      // ------------ testimonials-slider -----------
      var swiper = new Swiper('.testimonials-slider7 .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        // centeredSlides: true,
        speed: 1000,
        pagination: false,
        navigation: false,
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 5000,
        },
        loop: true,
      });

      // ------------ features-slider7 -----------
      var swiper = new Swiper('.services-slider8 .swiper-container', {
        spaceBetween: 50,
        // centeredSlides: true,
        // slidesPerView: 6,
        speed: 10000,
        autoplay: {
          delay: 1,
        },
        loop: true,
    //   allowTouchMove: false,
        disableOnInteraction: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          }
        }
      });

      // ------------ features-slider7 -----------
      var swiper = new Swiper('.imgs-content-slider .swiper-container', {
        spaceBetween: 50,
        // centeredSlides: true,
        // slidesPerView: 6,
        speed: 10000,
        autoplay: {
          delay: 1,
        },
        loop: true,
    //   allowTouchMove: false,
        disableOnInteraction: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          }
        }
      });

      // ------------ features-slider7 -----------
      var swiper = new Swiper('.careers-positions-slider5 .swiper-container', {
        spaceBetween: 30,
        // centeredSlides: true,
        // slidesPerView: 6,
        speed: 1000,
        autoplay: {
            delay: 5000,
        },
        loop: true,
        pagination: false,
        navigation: {
          nextEl: '.careers-positions-slider5 .swiper-button-next',
          prevEl: '.careers-positions-slider5 .swiper-button-prev',
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });
   
      // ------------ works sliders -----------
      var swiper = new Swiper('.projects-slider8 .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1200,
        pagination: false,
        navigation: {
          nextEl: '.projects.style-8 .swiper-button-next',
          prevEl: '.projects.style-8 .swiper-button-prev',
        },
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: false,
      });

      // ------------ about2-imgs-slider -----------
      var swiper = new Swiper('.about2-imgs-slider .swiper-container', {
        spaceBetween: 50,
        // centeredSlides: true,
        // slidesPerView: 6,
        speed: 10000,
        autoplay: {
          delay: 1,
        },
        loop: true,
    //   allowTouchMove: false,
        disableOnInteraction: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });

      // ------------ login sliders -----------
      var swiper = new Swiper('.sign-imgs-slider .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1000,
        pagination: {
          el: ".sign-imgs-slider .swiper-pagination",
          clickable: true,
        },
        navigation: false,
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 4000,
        },
        loop: true,
      });

      // ------------ login sliders -----------
      var swiper = new Swiper('.testimonials-slider11 .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1000,
        pagination: {
          el: ".testimonials-slider11 .swiper-pagination",
          clickable: true,
        },
        navigation: false,
        mousewheel: false,
        keyboard: true,
        autoplay: {
          delay: 6000,
        },
        loop: true,
      });


      // ------------ features-line-slider12 -----------
      var swiper = new Swiper('.features-line-slider12 .swiper-container', {
        spaceBetween: 0,
        centeredSlides: true,
        // slidesPerView: 6,
        speed: 10000,
        autoplay: {
          delay: 1,
        },
        loop: true,
    //   allowTouchMove: false,
        disableOnInteraction: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 2,
          }
        }
      });

      // ------------ collections-slider12 -----------
      var swiper = new Swiper('.collections-slider12 .swiper-container', {
        spaceBetween: 30,
        // centeredSlides: true,
        // slidesPerView: 6,
        speed: 1000,
        autoplay: {
          delay: 5000,
        },
        loop: false,
        pagination: false,
        navigation: false,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });

      // ------------ projects-slider13 -----------
      var swiper = new Swiper('.projects-slider13 .swiper-container', {
        spaceBetween: 30,
        // centeredSlides: true,
        // slidesPerView: 6,
        observer: true,
        observeParents: true,
        speed: 1000,
        autoplay: {
          delay: 5000,
        },
        loop: true,
        pagination: false,
        navigation: false,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 2,
          }
        }
      });
 
      // ------------ projects-slider13 -----------
      var swiper = new Swiper('.testimonials-slider14 .swiper-container', {
        spaceBetween: 50,
        // centeredSlides: true,
        // slidesPerView: 6,
        speed: 1000,
        autoplay: {
          delay: 5000,
        },
        loop: false,
        pagination: false,
        navigation: false,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          787: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });

    }); 
    //===============================================

    $(window).on("load", function() {
      $("#preloader").addClass("isdone");
    });

    // $( function() {
    //   // ---------- portfolio mixitup -----------
    //   var containerEl = document.querySelector('.mix-container');
    //   var mixer = mixitup(containerEl);
    // } );
 
  }
  
  thisComponentStyle(){
    const id = "WelcomeStyle";
    if( document.getElementById(id) == null ){
      const $style = document.createElement("style");
      $style.setAttribute("id", id);
      $style.setAttribute("class", "react-component-style");
      document.head.appendChild($style); 
      $style.innerHTML = `  
        .dropdown-item.label-dropdown-item:hover {
          background-color: inherit;
        }

        header.style-3 {
        	background-color: #ffffff;	
        }

        header.style-3 .content .info .h1 {
        	color: black;
        }

        header.style-3 .content .info .p {
        	color: black;
    			margin-top: 40px;
				  border-bottom: 3px solid red; /* 設置底線樣式 */  			
        } 

        .navbar.style-3.nav-scroll {
			    background-color: #fff !important;
			    opacity: .95;
				}

				.navbar.style-3 .navbar-nav .nav-item .nav-link {
				  color: black;
				}

				.navbar.style-3 .navbar-nav .nav-item .nav-link:hover {
				  color: red;
				}

				.section-head.style-3 h3 span {
					color: red;
				}

				.testimonials.style-3 .testimonial-slider.style-3 .swiper-button-prev:hover {
					color: red;
				}

				.swiper-button-next:after, .swiper-button-prev:after {
					color: red;
				}

				.testimonials.style-3 .testimonial-slider.style-3 .swiper-button-prev:hover {
			    background: red;
				}

				.testimonials.style-3 .testimonial-slider.style-3 .swiper-button-next:hover {
			    background: red; 
				}

				.navbar.style-3 .navbar-nav .nav-item .nav-link.active, .navbar.style-3 .navbar-nav .nav-item .nav-link:hover {
					background-color: rgb(143 143 143 / 13%); 
				} 
 
				.projects.style-3, .testimonials.style-3, .services.style-3, .pricing.style-3 {
					background-color: #f4f6f7;
				}

				.about.style-3 .img-left .info-circle {
					background-color: #e10047;
				}

				.about.style-3 .img-left .info-circle:nth-of-type(4) {
					background-color: #dc691c;
				}

				.bg-blue2 {
					background-color: #e80000 !important;
				}

				.pricing-card.style-3 {
					background-color: #ffffff;
				}

				.pricing-card.style-3 .card-body li i {
					color: #e80000 !important;
				}

				.pricing-card.style-3 .card-head .price h5 {
			    color: #e10000;
				}

				.border-blue2 {
					border-color: red !important;
				}

				footer.style-3 .foot {
					margin-top: 0px;
				}

				footer.style-3 {
					padding-top: 0px;
				}
 
				.bi.bi-star-fill, .bi.bi-chevron-compact-right {
					color: red;
				}

				.pricing-card.style-3 .card-head h4 {
					font-size: 24px;
				}

				.navbar-dark .navbar-toggler-icon {
			    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='red' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
				} 

				@media screen and (max-width: 991px) {
	 				.navbar.style-3 {
	 					background-color: white;
					} 
 
					.about.style-3 .img-left .info-circle:nth-of-type(4) {
				    width: 140px;
    				height: 140px;
					}

					.about.style-3 .img-left .info-circle:nth-of-type(2) {
				    width: 140px;
    				height: 140px;
					}
				}

      `; 
    } 
  }
  
 // #f0eff5
  componentDidMount() {  
    const that = this;    
    that.propsToInputData();
    that.inputDataToFormData();  
 
    that.thisComponentStyle();  
    if( window.opener && location.search.getQueryString("uuid") ){
      let thisWindow = new Models.WindowModel({uuid: location.search.getQueryString("uuid")}); 
      thisWindow.post_message_to_window(
        window.opener,
        "重新執行", 
        { 
          uuid: location.search.getQueryString("uuid")
        }
      ); 
    }
 

    $(document).ready(function(){
      that.thirdPartyPlugIn();
    });
 
  }
    
  render() {  
    const that = this;   
    return <React.Fragment>  
 
      <div id="preloader">
        <div id="loading-wrapper" className="show">
          <div id="loading-text"> <img src="../img/Soon_logo_1.png" alt="" /> </div>
          <div id="loading-content"></div>
        </div>
      </div> 
 
      <nav className="navbar navbar-expand-lg navbar-dark style-3 position-absolute w-100">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src="../img/Soon_logo_1.png" alt="" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0 text-uppercase">
              { false && //下拉範例
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    pages
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                    <li><a className="dropdown-item" href="page-about-5.html">about</a></li>
                    <li><a className="dropdown-item" href="page-product-5.html">product</a></li>
                    <li><a className="dropdown-item" href="page-services-5.html">services</a></li>
                    <li><a className="dropdown-item" href="page-shop-5.html">shop</a></li>
                    <li><a className="dropdown-item" href="page-single-project-5.html">single project</a></li>
                  </ul>
                </li>
              }
              <li className="nav-item">
                <a style={{ fontWeight: "bold", fontSize: "16px" }} className="nav-link" href="#aboutUs" onClick={e => {
                  $('html, body').animate({
                    scrollTop: ($("#aboutUs").offset().top - 100)
                  }, 200); 
                  e.preventDefault(); 
                  e.stopPropagation(); 
                }}>
                  關於我們
                </a>
              </li>
              <li className="nav-item">
                <a style={{ fontWeight: "bold", fontSize: "14px" }} className="nav-link" href="#aboutService" onClick={e => {
                  $('html, body').animate({
                    scrollTop: ($("#aboutService").offset().top - 100)
                  }, 200); 
                  e.preventDefault(); 
                  e.stopPropagation(); 
                }}>
                  服務內容
                </a>
              </li>
              <li className="nav-item">
                <a style={{ fontWeight: "bold", fontSize: "14px" }} className="nav-link" href="#aboutFeature" onClick={e => {
                  $('html, body').animate({
                    scrollTop: ($("#aboutFeature").offset().top - 100)
                  }, 200); 
                  e.preventDefault(); 
                  e.stopPropagation(); 
                }}>
                  系統特色
                </a>
              </li>
              <li className="nav-item">
                <a style={{ fontWeight: "bold", fontSize: "14px" }} className="nav-link" href="#aboutFee" onClick={e => {
                  $('html, body').animate({
                    scrollTop: ($("#aboutFee").offset().top - 100)
                  }, 200); 
                  e.preventDefault(); 
                  e.stopPropagation(); 
                }}>
                  收費標準
                </a>
              </li>

              { that.state.inputData?.sites && that.state.inputData.sites.length > 0 && 
                <li className="nav-item dropdown">
                  <a style={{ fontWeight: "bold", fontSize: "14px" }} className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    進入網站
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown1"> 
                    { that.state.inputData.sites.map((site, index) => 
                      <li key={index} >
                        <a style={{ fontSize: "14px" }} className="dropdown-item" href={`/${ site.domain }/backend`} target="_blank">{ site.title }{ !site.isActive && <span style={{ color: "red", marginLeft: "3px" }}>{ `(${ site.latest_system_confirmed_charged_record ? "已到期" : "尚未啟用" })` }</span> }</a>
                      </li>
                    )}
                  </ul>
                </li>
              } 

            </ul>
            <div className="nav-side">
              <div className="d-flex ps-4">
                { false && //搜尋放大鏡
                  <a href="#" className="search-icon me-5">
                    <i className="bi bi-search"></i>
                  </a>
                } 
                <div className="dropdown">
                  <button
                    className="icon-35 dropdown-toggle p-0 border-0 bg-transparent rounded-circle img-cover text-white"
                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i style={{ fontSize: "24px", color: "black" }} className={ that.props.reactProps.current_user ? "bi bi-person-check" : "bi bi-person-circle" }></i>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    { that.props.reactProps.current_user &&
                      <li><a style={{ fontWeight: "bold", fontSize: "14px" }} className="dropdown-item label-dropdown-item" href="#" onClick={e => { return e.preventDefault(); e.stopPropagation(); }}>會員: { that.props.reactProps?.current_user?.name }</a></li>
                    }  

                    { that.props.reactProps.current_user &&
                      <li><a style={{ fontSize: "14px" }} className="dropdown-item" href="/sites/new" target="_blank">申請新網站</a></li>
                    }

                    { that.props.reactProps.current_user &&
                      <li>
                        <a style={{ fontSize: "14px" }} className="dropdown-item" href="/users/sign_out" onClick={e => {
                          let $form = $("<form action='/users/sign_out' method='post'></form>");
                          $form.append('<input type="hidden" name="_method" value="delete">');
                          $form.append(`<input type="hidden" name="authenticity_token" value="${ $('meta[name="csrf-token"]').attr('content') }">`);
                          $form.appendTo("body");
                          $form.submit();
                          e.preventDefault(); 
                          e.stopPropagation(); 
                        }}>登出</a>
                      </li>
                    }                          

                    { !that.props.reactProps.current_user &&
                      <li><a style={{ fontSize: "14px" }} className="dropdown-item" href="/users/sign_in">登入</a></li>
                    }  
                    { !that.props.reactProps.current_user &&
                      <li><a style={{ fontSize: "14px" }} className="dropdown-item" href="/users/sign_up">註冊</a></li>
                    } 
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
 
      <header className="style-3 overflow-hidden">
        <div className="container">
          <div className="content section-padding">
            <div className="row">
              <div className="col-lg-5">
                <div className="info">
                  <h1 className="h1"> 讓你商品快速入單，輕鬆上手! <span>Soon＋</span></h1>
                  <p className="p" style={{ width: "fit-content" }}>我們提供更加快速的入單功能，讓你快速累績您的業績 </p>
                  <p className="p" style={{ marginTop: "10px", width: "fit-content" }}>以簡單明瞭的流程、讓你收單無往不利!!!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-img">
          <img style={{ 
          	width: "auto", 
          	height: "auto",
				    right: "initial",
				    top: "initial" 
           }} src="/Soon_plus_7.png" alt="" className="pattern" /> 
        </div>
      </header>

      <main>  
        <section id="aboutService" className="services style-3 section-padding pb-70">
          <div className="container">
            <div className="section-head style-3 d-flex align-items-center">
              <h3>市場系統  <span>比較</span></h3> 
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-6 mb-30 mb-lg-0">
                <div className="service-card style-3">
                  <div className="icon">
                    <img src="/img/serv_icons/10.png" alt="" />
                  </div>
                  <div className="info">
                    <h5 className="title">Soon+</h5>
                    <div className="text">
                      <ul>
                        <li style={{ fontSize: "16px" }}> <i className="bi bi-star-fill me-3"></i> 平均一天20元</li>
                        <li style={{ fontSize: "16px" }}> <i className="bi bi-star-fill me-3"></i> 跨社團整單，不限多個社團、粉專</li> 
                        <li style={{ fontSize: "16px" }}> <i className="bi bi-star-fill me-3"></i> 消費者無須登入填單</li>
                        <li style={{ fontSize: "16px" }}> <i className="bi bi-star-fill me-3"></i> 簡便，清楚明瞭</li>
                      </ul>
                    </div> 
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 mb-30 mb-lg-0">
                <div className="service-card style-3">
                  <div className="icon">
                    <img src="/img/serv_icons/11.png" alt="" />
                  </div>
                  <div className="info">
                    <h5 className="title">臉書其他系統</h5>
                    <div className="text">
                      <ul>
                        <li style={{ fontSize: "16px" }}> <i className="bi bi-chevron-compact-right me-3"></i> 價格普遍偏高</li>
                        <li style={{ fontSize: "16px" }}> <i className="bi bi-chevron-compact-right me-3"></i> 大多限定單一社團或粉專</li> 
                        <li style={{ fontSize: "16px" }}> <i className="bi bi-chevron-compact-right me-3"></i> 大多需要消費者登入填單</li>
                        <li style={{ fontSize: "16px" }}> <i className="bi bi-chevron-compact-right me-3"></i> 功能繁複，不知從何用起</li>
                      </ul>
                    </div> 
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </section>

          <section id="aboutFeature" className="about section-padding style-3">
            <div className="top-content">
              <div className="img img-left">
                <img src="/img/serv_icons/style_3_1.png" alt="" />
                <div className="info-circle">
                  <div className="cont">
                    <h2 style={{ fontSize: "24px" }}><span>最自主的</span><span>後台操作</span></h2>
                    <small>convenient</small>
                  </div>
                </div>
                <div className="info-circle">
                  <div className="cont">
                    <h2 style={{ fontSize: "24px" }}><span>最簡便的</span><span>多平台整單</span></h2>
                    <small>simple</small>
                  </div>
                </div>
                <div style={{ opacity: 0 }} className="info-circle"> {/* 隱藏一個 */}
                  <div className="cont">
                    <h2 style={{ fontSize: "24px" }}><span>銅板價</span><span>也能做生意</span></h2>
                    <small>price</small>
                  </div>
                </div>
                <div className="info-circle">
                  <div className="cont">
                    <h2 style={{ fontSize: "24px" }}><span>銅板價</span><span>也能做生意</span></h2>
                    <small>price</small>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row gx-0 justify-content-end">
                  <div className="col-lg-5">
                    <div className="info">
                      <div className="section-head long-shape mb-40 style-3">
                        <h3>生意要順！ <span>要有soon+</span></h3>
                      </div>
                      <h5 className="h5">
                        soon+讓你做生意簡便、快速、很有力！ 
                      </h5>
                      <div className="text mb-20">
                        <span style={{ display: "block" }}>臉書銷售最怕整理龐大訂單</span>
                        <span style={{ display: "block" }}>最怕客人不會登入，更怕要花很多時間上架指導</span>  
                      </div>  
                      <div className="text mb-70">
                        <span style={{ display: "block" }}>客戶等等問題！現在都不用無論你是要在不同社團、粉專</span>
                        <span style={{ display: "block" }}>或是個人版面貼文帶貨，客戶簡單+1團主訂單輕鬆到手。</span> 
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pricing style-3">
              <div className="container">
                  <div className="content section-padding">
                      <div className="row"> 
                          <div className="col-lg-12">
                            <div className="price-cards">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="pricing-card style-3 mb-30 mb-lg-0">
                                    <div className="card-head">
                                      <div className="title">
                                        <h4>月繳</h4> 
                                      </div>
                                      <div className="price">
                                        <h5>$600</h5> 
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <h5 style={{ fontWeight: "bold", margin: "5px 0px 10px 0px" }}>適用對象</h5> 
                                      <ul> 
                                        <li> 
                                          <i className="bi bi-check"></i>
                                          <small style={{ fontSize: "16px" }}>臨時有商品要進行短期銷售，建議選擇此方案</small>
                                        </li>
                                        <li> 
                                          <i className="bi bi-check"></i>
                                          <small style={{ fontSize: "16px" }}>想試試看是否自己是否做生意料的各種人</small>
                                        </li> 
                                      </ul>
                                    </div>  
                                    <a href="#" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                      <span>我要申請</span>
                                    </a>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="pricing-card style-3">
                                    <div className="card-head">
                                      <div className="title">
                                        <h4>季繳</h4> 
                                      </div>
                                      <div className="price">
                                        <h5>$1688</h5> 
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <h5 style={{ fontWeight: "bold", margin: "5px 0px 10px 0px" }}>適用對象</h5> 
                                      <ul> 
                                        <li>
                                          <i className="bi bi-check"></i>
                                          <small style={{ fontSize: "16px" }}>商品有季節銷售限制的，建議選擇此方案</small>
                                        </li>
                                        <li>
                                          <i className="bi bi-check"></i>
                                          <small style={{ fontSize: "16px" }}>想給自己三個月測試市場眼光的各種人</small>
                                        </li> 
                                      </ul>
                                    </div>
                                    <a href="#" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                      <span>我要申請</span>
                                    </a>
                                  </div>
                                </div> 
                                <div className="col-lg-4">
                                  <div className="pricing-card style-3">
                                    <div className="card-head">
                                      <div className="title">
                                        <h4>年繳</h4> 
                                      </div>
                                      <div className="price">
                                        <h5>$6000</h5> 
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <h5 style={{ fontWeight: "bold", margin: "5px 0px 10px 0px" }}>適用對象</h5> 
                                      <ul>
                                        <li>
                                          <i className="bi bi-check"></i>
                                          <small style={{ fontSize: "16px" }}>想要在社群行銷市場有一個銷售戰場的廠商</small>
                                        </li>
                                        <li>
                                          <i className="bi bi-check"></i>
                                          <small style={{ fontSize: "16px" }}>專心處理自己小商店外，也想到外地拓展客戶群</small>
                                        </li> 
                                      </ul>
                                    </div>
                                    <a href="#" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                                      <span>我要申請</span>
                                    </a>
                                  </div>
                                </div> 

                              </div>
                            </div>
                          </div>
                      </div>
                      <img src="../img/testimonials/testi3_lines.png" alt="" className="testi_lines w-100" />
                  </div>
              </div>
          </section>


      </main>

      <footer className="style-3">
        <div className="container">
          <div className="foot">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="logo">
                  <img src="/Soon_plus_2.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <small style={{ marginTop: "13px" }} className="small">
                  本站最佳瀏覽環境請使用Google Chrome 或 Microsoft Edge © 2024 Copyrights by Soon+ 
                </small>
              </div>
            </div>
            <img src="../img/testimonials/testi3_lines.png" alt="" className="testi_lines w-100" />
          </div>
        </div>
        <img src="../img/contact_globe.svg" alt="" className="contact_globe" />
      </footer>

      <a href="#" className="to_top bg-gray rounded-circle icon-40 d-inline-flex align-items-center justify-content-center">
          <i className="bi bi-chevron-up fs-6 text-dark"></i>
      </a>
   
    </React.Fragment>;
  }  
} 
  
window.reactComponents = window.reactComponents ? window.reactComponents : {};
window.reactComponents.WelcomeIndex = WelcomeIndex;