import React from 'react'
import ReactDOM from "react-dom"; 
import { CONSTANTS } from "../bundles/constants.es6.jsx"; 

class OrderStatus extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentStatus: this.props.currentStatus 
    };
 
    this.swap = this.swap.bind(this);
    this.selectOptions = this.selectOptions.bind(this);
    this.getEvent = this.getEvent.bind(this); 
  };
 
  swap(json){
    var ret = {};
    for(var key in json){
      ret[json[key]] = key;
    }
    return ret;
  }

  selectOptions(){ 
    let that = this;
    let allStatus = new Set();
    for(let key in CONSTANTS.Order.STATUS_EVENT) {  
      if(CONSTANTS.Order.STATUS_EVENT[key]["from"].indexOf(that.state.currentStatus) != -1){
        for(let index=0; index < CONSTANTS.Order.STATUS_EVENT[key]["to"].length ; index++ ){
          allStatus.add(CONSTANTS.Order.STATUS_EVENT[key]["to"][index]); 
        }
      }
    }  
    return Array.from(allStatus);   
  }

  getEvent(from, to){ 
    let that = this;
    let event = null;
    for(let key in CONSTANTS.Order.STATUS_EVENT) {  
      if(
        CONSTANTS.Order.STATUS_EVENT[key]["from"].indexOf(from) != -1 &&
        CONSTANTS.Order.STATUS_EVENT[key]["to"].indexOf(to) != -1
        ){
        event = key;
      }
    }  
    return event;   
  } 

  componentDidMount() {
    const that = this;
      
  };

  render () {
    const that = this; 

    return <React.Fragment>
      { that.selectOptions().length == 0 &&
        <span>{ that.swap(CONSTANTS.Order.STATUS)[that.state.currentStatus] }</span>
      }
      { that.selectOptions().length > 0 &&
        <select value={ that.state.currentStatus } name="orderStatus" className={ `${ that.props.required ? "required" : "" }` } onChange={(e)=>{ that.props.onChange.apply(this, [e, that.state.currentStatus, e.target.value , that.getEvent(that.state.currentStatus, e.target.value)]); that.setState({ currentStatus: e.target.value }); }} >
          <option value={ that.state.currentStatus }>{ that.swap(CONSTANTS.Order.STATUS)[that.state.currentStatus] }</option>
          { that.selectOptions().map((status, index) => 
            <option key={index} value={ status }>{ that.swap(CONSTANTS.Order.STATUS)[status] }</option>
          )}     
        </select>
      } 
    </React.Fragment>
  };
};

OrderStatus.defaultProps = { 
  required: false,
  currentStatus: "待確認",
  onChange: function(){} 
};

export { OrderStatus }   