const CONSTANTS = {
	Site: {
		INVOICE_TYPE: {
	    "二聯式發票" : 1,
	    "三聯式發票" : 2,
	    "捐贈發票" : 3
		},
		INVOICE_CARRIER_TYPE: {
	    "手機條碼" : 0,
	    "自然人憑證條碼" : 1,
	    "廠商電子發票載具" : 2
		}  
	},
	Product: {
		SEARCH_TYPE: {
	    "最新商品" : 1,
	    "熱銷排行" : 2 
		}  
	},	
	Role: {
		TYPE : {
			"最高管理者" : "admin",
			"系統管理者" : "manager",
			"網站管理者" : "site_manager",
			"資深客服人員" : "senior_service_staff",
			"客服人員" : "service_staff",
			"公告管理人員" : "news_staff",
			"會計人員" : "account_staff"
		}
	},
	News: {
		SCOPE: {
			"全部" : 1,
			"前台" : 2,
			"後台" : 3
		} 
	},
	Coupon: { 
		TYPE_CODE : { 
			"買多折扣" : 1, 
			"買多贈品" : 2, 
			"滿額紅利(回饋儲值金)" : 3,
			"滿額贈品" : 4,
			"滿額折扣" : 5
			// "加價購" : 1, 
		}, 
		DISCOUNT_TYPE : {
			"固定金額" : 1,
			"比率" : 2,
			// "免運" : 3,
			// "贈品" : 4
		}
	},
	CouponProductRelation: { 
		TYPE : { 
			"優惠商品" : 1, 
			"贈品" : 2 
		} 
	},
	CouponLimit: {
		RESOURCE_TYPE: {
			"會員" : "User",
			"會員等級" : "MemberLevel",
			"商品標籤" : "ProductTag"
		} 
	},
	Order: { 
		SOURCE: {
			"後台系統" : 1,
			"FB社團" : 2 
		}, 
		SALE_TYPE: {
			"現貨" : 1,
			"預購" : 2,
			"集貨" : 3,
			"贈品" : 4
		}, 
		STATUS: {
			"待確認" : "pending",
			"已確認" : "accepted",
			"已訂貨" : "buyed",
			"已到貨" : "arrived",
			"出貨處理中" : "processing",
			"暫緩出貨" : "stop_shipped",
			"完成" : "completed",
			"退貨中" : "refunding",
			"退貨完成" : "refunded",
			"取消" : "canceled",
			"現場結帳中" : "on_site_checkout"
		},  
		STATUS_EVENT: {
			"accept" : { "from" : ["pending"], "to" : ["accepted"] }, 
			"buy" : { "from" : ["pending", "accepted"], "to" : ["buyed"] }, 
			"arrive" : { "from" : ["pending", "accepted", "buyed"], "to" : ["arrived"] }, 
			"process" : { "from" : ["pending", "accepted", "buyed", "arrived"], "to" : ["processing"] }, 
			"stop_ship" : { "from" : ["pending", "accepted", "buyed", "arrived", "processing"], "to" : ["stop_shipped"] }, 
			"complete" : { "from" : ["processing"], "to" : ["completed"] }, 
			"restore_cancel" : { "from" : ["canceled"], "to" : ["pending"] }, 
			"cancel" : { "from" : [ "pending", "accepted", "buyed", "arrived", "processing", "stop_shipped" , "refunding", "refunded" ], "to" : ["canceled"] }, 
			"refund" : { "from" : ["processing", "completed"], "to" : ["refunding"] }, 
			"refund_complete" : { "from" : ["refunding"], "to" : ["refunded"] }
		} 
	},
	Shipment: {
		STATUS: {
			"待確認" : "pending",
			"備貨中" : "packing",
			"已備貨" : "packed",
			"已取貨" : "delivered", 
			"作廢" : "canceled", 
		},  
		STATUS_EVENT: {
			"pack" : { "from" : ["pending"], "to" : ["packing"] }, 
			"pack_complete" : { "from" : ["packing"], "to" : ["packed"] }, 
			"deliver" : { "from" : ["packed"], "to" : ["delivered"] } 
		} 
	},
	Advance: {
		TYPE_CODE : {
			"手動新增" : 1,
			"延遲付款" : 2,
			"取消訂單" : 3,
			"折抵" : 4,
			"沖銷" : 5,
			"作廢" : 6
		}
	},
	ChargedRecord : {
		PAY_METHOD : {
			"匯款" : 1, 
			"線上繳費" : 2 
		}, 
	  RECORD_TYPE : {
	    "系統使用費" : 0,
	    "其他" : 1,
	    "line加購" : 2,
	    "出貨機加購" : 3, 
	    "客製付費" : 4 
	  } 
	}
}
  
export { CONSTANTS } 